<template>
  <Dialog :header="isAdd?'添加展示项目':'编辑展示项目'" v-model:visible="display" @hide="$emit('close')" style="width: 50vw">
    <div class="p-grid" style="width: 90%;margin: 0 auto">
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">项目名称：</label>
        <div class="p-col">
          <InputText v-model="form.name" style="width:100%"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">项目标题：</label>
        <div class="p-col">
          <InputText v-model="form.title" style="width:100%"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">地址：</label>
        <div class="p-col" style="width: calc(100% - 110px)">
          <div style="display: flex;width: 100%;overflow: hidden">
            <InputText placeholder="国家" style="width: calc(25% - 1rem)" class="p-mr-3" v-model="form.country"/>
            <InputText placeholder="省" style="width: calc(25% - 1rem)" class="p-mr-3" v-model="form.province"/>
            <InputText placeholder="市" style="width: calc(25% - 1rem)" class="p-mr-3" v-model="form.city"/>
            <InputText placeholder="区" style="width: calc(25% - 2px)" v-model="form.district"/>
          </div>
          <InputText placeholder="详细地址" style="width: 100%" class="p-mt-3" v-model="form.location"/>
          <InputText placeholder="园区" style="width: 100%" class="p-mt-3" v-model="form.park"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>坐标：</label>
        <div class="p-col" style="width: calc(100% - 110px);height: 400px;position: relative">
          <div :id="'map'+uuid" style="width: 100%;height: 400px"></div>
          <InputText v-model="searchValue" style="position: absolute;left: 1rem ;top:1rem" id="searchAddress" @keydown.enter="addressChange"/>
          <div id="searchOutPut" v-if="searchResult && searchResult.length>0" style="position: absolute;left: 1rem ;top: calc(36px + 1rem);background: white;padding: 1rem;height: 250px ;overflow-y: scroll;border: 5px">
            <div v-for="item in searchResult" @click="toAddress(item)" style="margin-bottom: 1rem">
              <p style="color: #111111;font-size: 14px;margin: 0;line-height: 20px">
                {{ item.name }}
              </p>
              <p style="color: #79828a;font-size: 12px;cursor: pointer"> {{ item.district }}</p>

            </div>
          </div>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="(!form.lat ||!form.lon) && submitted">请选择坐标</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">照片：</label>
        <div class="p-col p-d-flex">
          <div style="width: 100px;height: 100px;border: 1px dashed #ced4da;margin-right: 1rem;display: flex;align-items: center;justify-content: center"
               @click="uploadImg()">
            <img style="width: 70%;height: 70%" src="@/assets/images/add.png">
          </div>
          <div class="p-mr-3" v-if="form.imgName" style="position: relative">
            <img style="height: 100px;" :src="$minioUrl+form.imgName">
            <span @click="removeImg()"
                  style="position: absolute;width: 20px;height: 20px;background: white;border: 1px solid #CED4DA;border-radius: 20px;right: -7px;top: -7px;display: flex;align-items: center;justify-content: center">
              <i class="pi pi-times" style="font-size: 10px"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">内容：</label>
        <div class="p-col" style="width: calc(100% - 110px)">
          <Textarea style="width: 100%" v-model="form.content" :autoResize="true" rows="4" cols="60"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">外链：</label>
        <div class="p-col">
          <InputText v-model="form.link" style="width:100%"/>
        </div>
      </div>
    </div>
    <template #footer>
      <Button @click="submit">提交</Button>
      <Button @click="display=false">取消</Button>
    </template>
  </Dialog>
</template>

<script>
let map_a, autocomplete;
export default {
  name: 'addOrUpdateProjectShow',
  data() {
    return {
      display: false,
      uuid: null,
      isAdd: false,
      searchValue: null,
      searchResult: null,
      form: {
        id: null,
        name: null,
        title: null,
        country: null,
        province: null,
        city: null,
        district: null,
        location: null,
        park: null,
        lon: null,
        lat: null,
        content: null,
        imgName: null,
        link: null,
      },
      submitted: false,
    };
  },
  created() {
    this.uuid = Math.floor(Math.random() * 1000000000);
  },
  methods: {
    init(data) {
      const _this = this;
      this.display = true;
      if (data.id) {
        this.form = data;
        this.isAdd = false;
      } else {
        this.isAdd = true;
      }
      this.$nextTick(() => {
        map_a = new AMap.Map('map' + this.uuid, {
          resizeEnable: true,
          layers: [
            new AMap.TileLayer.RoadNet(),
            new AMap.TileLayer.Satellite(),
          ],
          zoom: 8,
        });
        if (data.lon && data.lat) {
          new AMap.Marker({
            map: map_a,
            position: [data.lon, data.lat],
          });
          map_a.setZoomAndCenter(15, [data.lon, data.lat]);
        }
        map_a.on('click', function(event) {
          new AMap.Marker({
            map: map_a,
            position: event.lnglat,
          });
          map_a.setZoomAndCenter(15, event.lnglat);
          map_a.getCity(function(res) {
            _this.form.country = '中国';
            _this.form.province = res.province;
            _this.form.city = res.city;
            _this.form.district = res.district;
            _this.form.lon = event.lnglat.R;
            _this.form.lat = event.lnglat.Q;
          });
        });
        autocomplete = new AMap.Autocomplete({
          input: 'searchAddress',
        });
      });
    },
    addressChange() {
      const _this = this;
      autocomplete.search(this.searchValue, function(status, result) {
        _this.searchResult = result.tips;
      });
    },
    toAddress(item) {
      this.searchResult = null;
      this.searchValue = null;
      map_a.setZoomAndCenter(15, item.location);
    },
    uploadImg() {
      const _this = this;
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = function() {
        let file = this.files[0];
        let formData = new FormData();
        formData.append('file', file);
        _this.$axios.post('/minio/uploadFile', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        }).then((res) => {
          _this.form.imgName = res.data;
        });
      };
      input.click();
    },
    removeImg() {
      this.form.imgName = null;
    },
    submit() {
      this.submitted = true;
      if (!this.form.lat || !this.form.lon) {
        return;
      }
      this.$axios.post('/projectShow/addOrUpdate', this.form).then(() => {
        this.$toast.add({severity: 'success', summary: this.isAdd ? '新增成功' : '修改成功', life: 3000});
        this.display = false;
      });
    },
  },
};
</script>

<style scoped>
label {
  width: 100px;
  text-align: right;
  justify-content: flex-end;
}

.p-grid{
  margin-bottom: 20px;
}
</style>