<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card">
    <Button v-permission="['planProject','22','showproject','tjxm']" class="p-mb-3" @click="addOrUpdate">添加项目
    </Button>
    <data-table :value="list" row-hover="true">
      <column header="项目名称" field="name"></column>
      <column header="标题" field="title"></column>
      <column header="地址 ">
        <template #body="row">
          {{
            row.data.country + row.data.province + row.data.city + row.data.district + (row.data.location || '') +
            (row.data.park || '')
          }}
        </template>
      </column>
      <column header="图片">
        <template #body="row">
          <img v-if="row.data.imgName" style="height: 80px;width:140px" :src="$minioUrl+row.data.imgName"/>
        </template>
      </column>
      <column header="介绍" field="content"></column>
      <column header="外链" field="link"></column>
      <column header="操作">
        <template #body="row">
          <a v-permission="['planProject','22','showproject','zsbj']"
             style="padding-right:8px; margin:0;border-right:1px solid rgba(216, 216, 216, 1)" href="javascript:"
             class="a-button" @click="addOrUpdate(row.data)">编辑</a>
          <a v-permission="['planProject','22','showproject','zssc']" href="javascript:"
             class="a-button p-ml-2" style="color: red" @click="deleteItem(row.data)">删除</a>
        </template>
      </column>
    </data-table>
    <Paginator :rows="page.size" :totalRecords="page.total"
               @page="changePage"
               template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共{totalRecords} 条记录">
      <template #right>
        <label>跳至&nbsp;&nbsp;</label>
        <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                     inputStyle="width: 40px;"/>
        <label>&nbsp;&nbsp;页</label>
      </template>
    </Paginator>
    <add-or-update-project-show v-if="addOrUpdateDisplay" ref="addOrUpdate"
                                @close="closeDialog()"></add-or-update-project-show>
  </div>
</template>

<script>
import AddOrUpdateProjectShow from '@/views/modules/project/projectShow/addOrUpdateProjectShow';

export default {
  name: 'project_show',
  components: { AddOrUpdateProjectShow },
  data() {
    return {
      list: [],
      page: {
        current: 1,
        total: 0,
        size: 10,
      },
      addOrUpdateDisplay: false,
      breadcrumb: {
        home: { label: "种植项目", to: "#" },
        items: [
          { label: "项目管理", to: "#" },
          { label: "展示项目管理", to: "#" },
        ],
      },

    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.get('/projectShow/getPage', {
        params: {
          current: this.page.current,
          size: this.page.size,
        },
      }).then(res => {
        this.list = res.data.records;
        this.page.total = res.data.total;
      });
    },
    closeDialog() {
      this.addOrUpdateDisplay = false;
      this.getList();
    },
    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList();
    },
    addOrUpdate(data) {
      this.addOrUpdateDisplay = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(data);
      });
    },
    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除"' + data.name + '"吗?',
        header: '删除本条数据',
        icon: 'pi pi-info-circle',
        acceptLabel: '确定',
        accept: () => {
          this.$axios.delete('/projectShow/del/' + data.id).then(() => {
            this.$toast.add({ severity: 'success', summary: '删除成功', life: 3000 });
            this.getList();
            this.$confirm.close();
          });
        },
        rejectLabel: '取消',
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
.a-button {
  color: #12A25D;
  font-size: 14px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
